import React, { useState, useContext } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import axios, { AxiosError } from 'axios';
import withSession from '../lib/session';
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import GlobalContext from '../context/GlobalContext';
import { emailIsValid } from '../helpers/helpers';
import CloseIcon from '@material-ui/icons/Close';
import { LOGIN_APP_URL } from '../utils/apiUrls';
import { envVariables } from '../config/config';
import PageHeadTags from '../components/PageHeadTags';
import { Home_PAGE } from '../utils/pagesUrls';
import { TOKEN } from '../utils/localStorage';

const Login = () => {
  const { t } = useTranslation(['error', 'common']);
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const gContext = useContext(GlobalContext);

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordError('');
    setPassword(event.target.value);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    setEmail(event.target.value);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const validation = () => {
    let valid = true;
    if (!email) {
      setEmailError('error:email_required');
      valid = false;
    } else if (!emailIsValid(email)) {
      setEmailError('error:email_valid');
      valid = false;
    }
    if (!password) {
      setPasswordError('error:password_required');
      valid = false;
    }
    return valid;
  };

  const onLoginClick = async () => {
    const valid = validation();
    if (valid) {
      setLoading(true);
      axios
        .post(`${envVariables.APP_URL}${LOGIN_APP_URL}`, {
          email: email,
          password: password,
        })
        .then((response) => {
          if (response?.data) {
            router.push(Home_PAGE, null, { shallow: true });
            setLoading(false);
          }
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          const errorMsg = error?.response?.data ? 'error:login_error' : 'error:undefined';
          gContext?.openSnackBar('error', t(errorMsg));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <PageHeadTags />

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        autoHideDuration={6000}
        className={`snack-bar-${gContext?.snackBarSevirity}`}
        open={gContext?.snackBar}
        onClose={() => gContext?.closeSnackBar()}
        message={gContext?.snackBarTxt}
        action={
          <>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => gContext?.closeSnackBar()}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <h1 style={{ display: 'none' }}>nouvelair</h1>
      <Grid container justifyContent="center" alignItems="center" direction="column" className="login-container">
        <FormControl error={Boolean(emailError)} variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-email">{t('common:email')}</InputLabel>
          <OutlinedInput
            labelWidth={70}
            id="outlined-adornment-email"
            value={email}
            onChange={handleChangeEmail}
            aria-describedby={t('common:email')}
          />
          <FormHelperText>{t(emailError)}</FormHelperText>
        </FormControl>

        <FormControl variant="outlined" fullWidth error={Boolean(passwordError)}>
          <InputLabel htmlFor="outlined-adornment-password">{t('common:password')}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handleChangePassword}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label={t('common:password')}
                  onClick={() => setShowPassword((prevValue: boolean) => !prevValue)}
                  onMouseDown={handleMouseDown}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={100}
          />
          <FormHelperText>{t(passwordError)}</FormHelperText>
        </FormControl>
        <Button
          aria-label={t('common:login')}
          className="login-btn"
          onClick={() => {
            onLoginClick();
          }}
          disabled={loading}
        >
          {t('common:login')}
          {loading ? <CircularProgress className="loader-login" /> : ''}
        </Button>
      </Grid>
    </>
  );
};

export const getServerSideProps = withSession(async function ({ req, locale }) {
  const token = req.session.get(TOKEN);

  if (token || !envVariables.REQUIRE_LOGIN) {
    return {
      redirect: {
        destination: `/${locale}/`,
        permanent: false,
      },
    };
  }
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'footer', 'menu', 'search', 'error', 'cookies'])),
    },
  };
});

export default Login;
